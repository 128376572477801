import { FC, FormEvent, useState } from 'react';
import {
  StripeCardCvcElementOptions,
  StripeCardExpiryElementChangeEvent,
  StripeCardExpiryElementOptions,
  StripeCardNumberElementChangeEvent,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import styles from './PayWithCard.module.css';
import { stripeElementStyle } from '../Styles/Style';
import c from 'classnames';

interface FormPaymentCardProps {
  onSubmit: (dados: FormEvent<HTMLFormElement>) => void;
  invoiceAmountDue: number;
  invalidNameMessage: string;
  setInvalidNameMessage: (value: string) => void;
  nameOnCard: string;
  setNameOnCard: (value: string) => void;
}

export const FormPaymentCard: FC<FormPaymentCardProps> = props => {
  const {
    onSubmit,
    invoiceAmountDue,
    setInvalidNameMessage,
    setNameOnCard,
    nameOnCard,
    invalidNameMessage,
  } = props;
  const [invalidCardNumberMessage, setInvalidCardNumberMessage] = useState('');
  const [invalidCardExpiracyMessage, setInvalidCardExpiracyMessage] = useState('');

  const stripeCardNumberElementOptions: StripeCardNumberElementOptions = {
    showIcon: true,
    iconStyle: 'default',
    placeholder: '',
    style: stripeElementStyle,
  };

  const cardExpiryElementOptions: StripeCardExpiryElementOptions = {
    placeholder: '',
    style: stripeElementStyle,
  };

  const stripeCardCvcElementOptions: StripeCardCvcElementOptions = {
    placeholder: '',
    style: stripeElementStyle,
  };

  const StripeNameElementChangeEvent = {
    placeholder: '',
    style: stripeElementStyle,
  };

  const handleNameChange = (e: string) => {
    setInvalidNameMessage('');
    setNameOnCard(e);
  };

  const handleCardNumberChange = (e: StripeCardNumberElementChangeEvent) => {
    setInvalidCardNumberMessage('');
    if (e.error) {
      setInvalidCardNumberMessage(e.error.message);
    }
  };

  const handleCardExpiracyChange = (e: StripeCardExpiryElementChangeEvent) => {
    setInvalidCardExpiracyMessage('');

    if (e.error) {
      setInvalidCardExpiracyMessage(e.error.message);
    }
  };

  return (
    <div className={styles.box}>
      <form onSubmit={onSubmit}>
        <p className={styles.title}>Pay With Card</p>
        <hr className={c('fmc-divider--horizontal', styles.hr)}></hr>

        <label htmlFor="nameOnCard">Name on Card</label>
        <input
          onChange={e => handleNameChange(e.target.value)}
          autoFocus
          placeholder={StripeNameElementChangeEvent.placeholder}
          type="text"
          value={nameOnCard}
          name="nameOnCard"
          className={styles.nameOnCard}></input>
        {invalidNameMessage && (
          <div className={styles.invalidCardMessage}>{invalidNameMessage}</div>
        )}
        <label>Credit Card Number</label>
        <CardNumberElement
          className={styles.cardElements}
          options={stripeCardNumberElementOptions}
          onChange={handleCardNumberChange}
        />
        {invalidCardNumberMessage && (
          <div className={styles.invalidCardMessage}>{invalidCardNumberMessage}</div>
        )}

        <div className={styles.inlineContainer}>
          <div className={styles.inlineItem}>
            <label>
              Expiration Date <br />
              Month / Year
            </label>
            <CardExpiryElement
              className={styles.cardElements}
              options={cardExpiryElementOptions}
              onChange={handleCardExpiracyChange}
            />
          </div>

          <div className={styles.inlineItem}>
            <label>CVC</label>
            <CardCvcElement className={styles.cardElements} options={stripeCardCvcElementOptions} />
          </div>
        </div>
        {invalidCardExpiracyMessage && (
          <div className={styles.invalidCardMessage}>{invalidCardExpiracyMessage}</div>
        )}
        <button type="submit" className={c('fmc-button', styles.payButton)}>
          Pay Now ${invoiceAmountDue.toFixed(2)}
          <span className="fds-icon fds-font--ford-icons__chevron-right fds-icon--offset-right" />
        </button>
      </form>
    </div>
  );
};
