import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { UsePaymentRequestProps, usePaymentRequest } from './usePaymentRequest';
import styles from './PaymentButtons.module.css';
import c from 'classnames';

function PaymentButtons(props: UsePaymentRequestProps) {
  const { canMakePayment, paymentButtonOptions } = usePaymentRequest(props);

  if (!canMakePayment) return <></>;

  return (
    <div className={styles.boxWallet}>
      <div>
        <h1 className={c(styles.title, styles.marginTop)}>Pay With Digital Wallet</h1>
        <hr className={c('fmc-divider--horizontal', styles.marginTop, styles.marginBotton)} />
        <PaymentRequestButtonElement className={styles.button} options={paymentButtonOptions} />
      </div>
    </div>
  );
}

export default PaymentButtons;
