import { useStripe } from '@stripe/react-stripe-js';
import { useState, useEffect, useCallback } from 'react';
import { cardPaymentConfirmed, userCanMakePayment } from '../../services/stripeUtils';
import InvoiceModel from '../../models/InvoiceModel';

export interface UsePaymentRequestProps {
  invoice: InvoiceModel;
  setPaid: (value: boolean) => void;
}

const usePaymentRequest = (props: UsePaymentRequestProps) => {
  const { setPaid, invoice } = props;

  const [paymentButtonOptions, setPaymentButtonOptions] = useState<any>(null);
  const [paymentRequest, setPaymentRequest] = useState<any | null>(null);
  const [canMakePayment, setCanMakePayment] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    if (stripe != null && paymentRequest == null && invoice != null) {
      const request = stripe.paymentRequest({
        country: 'US',
        currency: invoice.items[0].currency,
        total: {
          label: 'Service Payment',
          amount: invoice.invoiceAmountDue * 100,
        },
        requestPayerName: true,
        requestPayerEmail: false,
      });

      setPaymentRequest(request);
    }
  }, [stripe, paymentRequest]);

  const confirmPayment = useCallback(
    async (event: any) => {
      const paymentConfirmed = await cardPaymentConfirmed({
        stripeInstance: stripe,
        paymentIntent: invoice.paymentIntentSecretId,
        paymentMethod: event.paymentMethod.id,
      });

      if (!paymentConfirmed) return;

      setPaid(true);
      event.complete('success');
    },
    [invoice, stripe],
  );

  useEffect(() => {
    initializePaymentRequestData();

    async function initializePaymentRequestData() {
      if (paymentRequest == null) return;

      paymentRequest.on('paymentmethod', confirmPayment);
      setCanMakePayment(await userCanMakePayment(paymentRequest));
      setPaymentButtonOptions({
        paymentRequest,
        style: {
          paymentRequestButton: {
            theme: 'dark',
            height: '48px',
            type: 'default',
          },
        },
      });
    }

    return () => {
      if (paymentRequest == null) return;
      paymentRequest.off('paymentmethod', confirmPayment);
    };
  }, [paymentRequest, confirmPayment]);

  return {
    stripe,
    canMakePayment,
    paymentRequest,
    paymentButtonOptions,
  };
};

export { usePaymentRequest };
