import { Elements } from '@stripe/react-stripe-js';
import InvoiceItems from '../InvoiceItems/InvoiceItems';
import PaymentButtons from '../PaymentButtons/PaymentButtons';
import PayWithCard from '../PayWithCard/PayWithCard';
import styles from '../../pages/Checkout/Checkout.module.css';
import c from 'classnames';
import { FC, useState } from 'react';
import InvoiceModel from '../../models/InvoiceModel';
import { CssFontSource, CustomFontSource, Stripe, StripeElementsOptions } from '@stripe/stripe-js';

interface InvoiceDetailsProps {
  invoice: InvoiceModel;
  paid: boolean;
  setPaid: (value: boolean) => void;
  stripe: Stripe | null;
  clientSecret: string;
  setIsError: (value: boolean) => void;
  color: string;
  error: boolean;
}

export const RightPanelCheckout: FC<InvoiceDetailsProps> = props => {
  const { invoice, paid, setPaid, stripe, clientSecret, setIsError, color } = props;
  const [displayButton, setDisplayButton] = useState(false);

  const fontSrc = `${window.location.origin}/assets/fonts/ford/antenna-regular.woff}`;

  const fonts: Array<CssFontSource | CustomFontSource> = [
    {
      src: `url(${fontSrc})`,
      family: 'FordAntenna',
    },
  ];

  const openCard = () => setDisplayButton(true);

  const stripeElementsOptions: StripeElementsOptions = {
    clientSecret,
    fonts,
  };

  return (
    <div
      className={c('fds-layout-grid__cell--span-6', styles.fdslayoutRight, {
        [styles.layoutContainerPaid]: invoice.invoiceStatus === 'paid',
        [styles.layoutContainer]: invoice.invoiceStatus !== 'paid',
      })}>
      <div className={styles.desktopItems}>
        <InvoiceItems
          dueDate={invoice.dueDate}
          customerAddress={invoice.custommerAddressDto}
          customerName={invoice.customerName}
          orderNumber={invoice.invoiceNumber}
          color={color}
        />
      </div>
      {paid && (
        <p className={styles.messagePaid}>
          Thank you for your payment.
          <br />
          We've emailed you a receipt for your records.
        </p>
      )}
      {clientSecret && stripe && !paid && (
        <Elements options={stripeElementsOptions} stripe={stripe}>
          <PaymentButtons invoice={invoice} setPaid={setPaid} />

          {displayButton === false && (
            <button
              hidden={displayButton}
              onClick={() => openCard()}
              className={c('fmc-button', styles.payButton, styles.buttonMobile)}>
              Pay With Card
            </button>
          )}
          {
            <div className={c({ [styles.desktopItems]: displayButton === false })}>
              <PayWithCard
                invoice={invoice}
                setPaid={setPaid}
                invoiceAmountDue={invoice.invoiceAmountDue}
                setIsError={setIsError}
              />
            </div>
          }
        </Elements>
      )}
    </div>
  );
};
