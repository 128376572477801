export default function TermsAndConditions() {
  return (
    <>
      <p className="fmc-type--content2 fds-color__text--gray2">
        © 2022 Ford Motor Company <br />
        Powered by Stripe
      </p>
      <br />
      <p className="fmc-type--content2">
        <a href="#" className="fmc-divider--right fmc-pr-3">
          Terms and Conditions
        </a>
        <a href="#" className="fmc-pl-3 ">
          Privacy Policy
        </a>
      </p>
    </>
  );
}
