import c from 'classnames';

export interface MessageProps {
  error: boolean;
  message: string;
}

export function Message(props: MessageProps) {
  const { error, message } = props;
  return (
    <div
      className={c('fmc-top-banner', 'fmc-top-banner--persistent', {
        'fmc-top-banner--error': error,
        'fmc-top-banner--success': !error,
      })}
    >
      <div className="fmc-top-banner__inner">
        <span className="fmc-top-banner__content">{message}</span>
      </div>
    </div>
  );
}
