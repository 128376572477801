import { Stripe } from '@stripe/stripe-js';

export async function userCanMakePayment(paymentRequest: any): Promise<boolean> {
  if (paymentRequest == null) return false;
  if (paymentRequest.canMakePayment == null) return false;

  const result = await paymentRequest.canMakePayment();
  return result != null;
}

export interface CardPaymentConfirmedArgs {
  paymentIntent: string;
  paymentMethod: string;
  stripeInstance: Stripe | null;
}

export async function cardPaymentConfirmed(args: CardPaymentConfirmedArgs) {
  const { paymentIntent, paymentMethod: payment_method, stripeInstance } = args;

  if (stripeInstance == null) throw new Error('Stripe instance not properly initialized');

  const confirmResult = await stripeInstance.confirmCardPayment(
    paymentIntent,
    { payment_method },
    { handleActions: false },
  );

  return confirmResult != null && !confirmResult.error;
}
