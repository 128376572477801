import { FC, Key } from 'react';
import styles from '../Details/Details.module.css';

interface ItemsProps {
  taxRatesProps: any;
  amountProps: number;
}

const taxRates: FC<ItemsProps> = props => {
  const { taxRatesProps, amountProps } = props;

  function taxValue(amount: number, percentage: number) {
    return amount * (percentage / 100);
  }

  return (
    <>
      {taxRatesProps.map((taxAmount: { displayName: string; percentage: number }, index: Key) => (
        <div key={index + 'a'} className={styles.serviceDetailsPanelItem}>
          <span>{`${taxAmount.displayName} ${taxAmount.percentage}%`}</span>
          <span className={styles.value}>
            ${taxValue(amountProps, taxAmount.percentage).toFixed(2)}
          </span>
          <hr
            className={[
              'fmc-divider--horizontal',
              styles.serviceDetaiserviceDetailsPanelDivider,
            ].join(' ')}></hr>
        </div>
      ))}
    </>
  );
};

export default taxRates;
