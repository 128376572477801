const getJScript = (url: string, async = true, crossOrigin = 'anonymous') => {
  // eslint-disable-next-line no-undef
  const doc = document;
  const script = doc.createElement('script');
  script.src = url;
  script.async = async;

  script.crossOrigin = crossOrigin;
  doc.body.appendChild(script);
  return () => {
    doc.body.removeChild(script);
  };
};
export default getJScript;
