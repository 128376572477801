import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Approval from './pages/Approval/Approval';
import Checkout from './pages/Checkout/Checkout';
import useEffectOnce from './utils/useEffectOnce';
import getJScript from './services/connectionFordPay';
import CheckoutFordPay from './pages/CheckoutFordPay/CheckoutFordPay';
import CheckoutFinalizedFordPay from './pages/CheckoutFordPay/CheckoutFinalizedFordPay';

function App() {
  const urlFord = process.env.REACT_APP_SCRIPT_FORD_PAY;

  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute('data-brand', 'ford');
  }, []);

  useEffectOnce(() => getJScript(urlFord || ''));

  return (
    <Router>
      <Routes>
        <Route path="/checkout/:jwtFromUrl" element={<Checkout />} />
        <Route path="/checkoutfinalized/" element={<CheckoutFinalizedFordPay />} />
        <Route path="/checkoutfordpay/:jwtFromUrl" element={<CheckoutFordPay />} />
        <Route path="/approval/:jwtFromUrl" element={<Approval />} />
      </Routes>
    </Router>
  );
}

export default App;
