import { ActivityIndicator } from '../Loading/ActivityIndicator';
import { loadingSpinnerBig } from '../Styles/Style';
import styles from './Header.module.css';

interface Props {
  dealerLogoUrl: string;
  loading: boolean;
}

export default function Header({ dealerLogoUrl, loading }: Props) {
  return (
    <>
      <div className={styles.header}>
        <img className={styles.logo} src={dealerLogoUrl} alt="Logo" />
      </div>
      {loading && <ActivityIndicator style={loadingSpinnerBig} />}
    </>
  );
}
