import InvoiceItems from '../InvoiceItems/InvoiceItems';
import styles from '../../pages/Checkout/Checkout.module.css';
import c from 'classnames';
import { FC } from 'react';
import PayWithFordPay from '../PayWithFordPay/PayWithFordPay';
import InvoiceModelFordPay from '../../models/InvoiceModelFordPay';

interface InvoiceDetailsProps {
  invoice: InvoiceModelFordPay;
  color: string;
  paid: boolean;
  setPaid: (value: boolean) => void;
  setIsError: (value: boolean) => void;
  error: boolean;
}

export const RightPanelCheckout: FC<InvoiceDetailsProps> = props => {
  const { invoice, paid, color } = props;
  return (
    <div
      className={c('fds-layout-grid__cell--span-6', styles.fdslayoutRight, {
        [styles.layoutContainerPaid]: invoice.invoiceStatus === 'paid',
        [styles.layoutContainer]: invoice.invoiceStatus !== 'paid',
      })}>
      <div className={styles.desktopItems}>
        <InvoiceItems
          dueDate={invoice.dueDate}
          customerAddress={invoice.custommerAddressDto}
          customerName={invoice.customerName}
          orderNumber={invoice.invoiceNumber}
          color={color}
        />
      </div>
      {paid ? (
        <p className={styles.messagePaid}>
          Thank you for your payment.
          <br />
          We've emailed you a receipt for your records.
        </p>
      ) : (
        invoice &&
        invoice.azureAdToken &&
        invoice.paymentInfoToken &&
        !paid && (
          <PayWithFordPay azureJwt={invoice.azureAdToken} paymentToken={invoice.paymentInfoToken} />
        )
      )}
    </div>
  );
};
