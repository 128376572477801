import { Stripe } from '@stripe/stripe-js';
import React, { useEffect } from 'react';
import InvoiceModel from '../../models/InvoiceModel';
import InvoiceModelFordPay from '../../models/InvoiceModelFordPay';
import QuoteModel from '../../models/QuoteModel';
import { Services } from '../../services/api';
import { initializeStripe } from './Stripe';

interface StripeInvoiceProps {
  jwtFromUrl: string | undefined;
}

export function useStripeInvoice(props: StripeInvoiceProps) {
  const { jwtFromUrl } = props;
  const [clientSecret, setClientSecret] = React.useState('');
  const [invoice, setInvoice] = React.useState<InvoiceModel>();
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [stripe, updateStripe] = React.useState<Stripe | null>(null);
  const [loading, setLoading] = React.useState(true);
  const [paid, setPaid] = React.useState(false);

  useEffect(() => {
    if (jwtFromUrl) {
      Services.getInvoice(jwtFromUrl)
        .then(data => {
          setInvoice(data);
          if (data.invoiceStatus === 'paid') {
            setPaid(true);
          }
          setClientSecret(data.paymentIntentSecretId);
          initializeStripe(data.accountId)
            .then(result => {
              updateStripe(result);
            })
            .catch(() => {
              setIsError(true);
              setLoading(false);
            });
          setLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setLoading(false);
        });
    }
  }, [jwtFromUrl]);

  return { invoice, clientSecret, stripe, loading, paid, isErrorr, setPaid, setIsError };
}

export function useStripeApproval(props: StripeInvoiceProps) {
  const { jwtFromUrl } = props;
  const [quote, setQuote] = React.useState<QuoteModel>();
  const [pdf, setPdf] = React.useState('');
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [accept, setAccept] = React.useState(false);

  useEffect(() => {
    if (jwtFromUrl) {
      Services.getQuote(jwtFromUrl)
        .then(data => {
          if (data.quoteDto.status === 'accepted') {
            setAccept(true);
          }
          Services.getPDFQuote(jwtFromUrl).then(pdfData => {
            setPdf(pdfData);
          });
          setQuote(data);
          setLoading(false);
        })
        .catch(err => {
          console.warn('Error loading Stripe Approval:', err);
          setIsError(true);
          setLoading(false);
        });
    }
  }, [jwtFromUrl]);

  return { quote, loading, isErrorr, setIsError, pdf, accept, setAccept };
}


export function useFordPayInvoice(props: StripeInvoiceProps) {
  const { jwtFromUrl } = props;
  const [stripe, updateStripe] = React.useState<Stripe | null>(null);
  const [clientSecret, setClientSecret] = React.useState('');
  const [azureJwt, setAzureJwt] = React.useState('');
  const [paymentToken , setPaymentToken] = React.useState('')
  const [isErrorr, setIsError] = React.useState<boolean>(false);
  const [invoice, setInvoice] = React.useState<InvoiceModelFordPay>();

  const [paid, setPaid] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  

  useEffect(() => {
    if (jwtFromUrl) {
      Services.getInvoiceFordPay(jwtFromUrl)
        .then(data => {
          setInvoice(data);
          setAzureJwt(data.azureAdToken)
          setPaymentToken(data.paymentInfoToken)
          if (data.invoiceStatus === 'paid') {
            setPaid(true);
          }
          setClientSecret(data.paymentIntentSecretId);
          initializeStripe(data.accountId)
            .then(result => {
              updateStripe(result);
            })
            .catch(() => {
              setIsError(true);
              setLoading(false);
            });
          setLoading(false);
        })
        .catch(() => {
          setIsError(true);
          setLoading(false);
        });
    }
  }, [jwtFromUrl]);

  return { invoice, clientSecret, stripe, loading, paid, isErrorr, setPaid, setIsError,azureJwt,paymentToken };
}