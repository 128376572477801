import { FC } from 'react';
import InvoiceDetails from '../Details/Details';
import { CustomerAddress, Item } from '../../models/InvoiceModel';
import styles from '../../pages/Checkout/Checkout.module.css';
import c from 'classnames';
import { TermsAndConditionsStyled } from '../Styles/Style';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

interface InvoiceDetailsProps {
  invoicePdfLink: string;
  message: string;
  invoiceAmountDue: number;
  dueDate: string;
  customerAddress: CustomerAddress;
  customerName: string;
  invoiceNumber: string;
  items: Item[];
  color: string;
  accept?: boolean;
}

export const LeftPanel: FC<InvoiceDetailsProps> = props => {
  const {
    invoiceAmountDue,
    invoiceNumber,
    invoicePdfLink,
    dueDate,
    customerAddress,
    customerName,
    items,
    message,
    color,
    accept,
  } = props;

  return (
    <div
      className={c('fds-layout-grid__cell--span-6', styles.fdslayoutLeft, styles.layoutContainer)}>
      <InvoiceDetails
        orderAmountDue={invoiceAmountDue}
        dueDate={dueDate}
        accept={accept}
        customerAddress={customerAddress}
        customerName={customerName}
        orderNumber={invoiceNumber}
        message={message}
        orderPdfLink={invoicePdfLink}
        items={items}
        color={color}
      />
      <TermsAndConditionsStyled>
        <TermsAndConditions />
      </TermsAndConditionsStyled>
    </div>
  );
};
