import { useEffect, useRef } from "react";

export default function useEffectOnce(fn: () => void) {
  const ref = useRef(true);
  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      fn();
    }
  }, [fn]);
}