import { Line, Spinner } from '../Styles/Style';

interface ActivityProps {
  invert?: boolean;
  size?: string;
  style?: object;
}

export function ActivityIndicator(props: ActivityProps) {
  const colors = props.invert
    ? {
        lineColor: 'rgba(255, 255, 255, 0.5)',
        activityLineColor: 'var(--fds-color--white)',
      }
    : {
        lineColor: 'var(--fds-color--gray2)',
        activityLineColor: 'var(--fds-color--primary)',
      };

  const diameter = props.size ?? '8rem';

  return (
    <div data-testid="loading-spinner" style={{ ...props.style }}>
      <Line diameter={diameter} lineColor={colors.lineColor} offset={'0.2rem'}>
        <Spinner
          activityLineColor={colors.activityLineColor}
          diameter={diameter}
          offset={'0.2rem'}
          loaderWeight={'0.3rem'}
        />
      </Line>
    </div>
  );
}
