import { useState, FC } from 'react';
import c from 'classnames';
import styles from '../../pages/Checkout/Checkout.module.css';

interface PayWithFordPayProps {
  paymentToken: string;
  azureJwt: string;
}

const PayWithFordPay: FC<PayWithFordPayProps> = props => {
  const { azureJwt, paymentToken } = props;
  const [customerAuthToken] = useState('');
  const redirectURL = `${location.origin}/checkoutfinalized`;
  return (
    <div style={{ marginTop: '20px' }}>
      <h1 className={c(styles.title)}>Pay With FordPay</h1>
      <hr className={c('fmc-divider--horizontal')} />
      <fordpay-checkout
        azure-ad-token={azureJwt.replace(/"/g, '')}
        payment-info-token={paymentToken}
        redirect-url={redirectURL ? redirectURL : ''}
        customer-auth-token={customerAuthToken}
        is-pre-auth={false}
      />
    </div>
  );
};

export default PayWithFordPay;
