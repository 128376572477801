import { useParams } from 'react-router-dom';
import styles from './Checkout.module.css';
import Header from '../../components/Header/Header';
import React, { useEffect } from 'react';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import c from 'classnames';
import { Message } from '../../components/Message/Message';
import { LeftPanel } from '../../components/LeftPanel/LeftPanelInvoice';
import { RightPanelCheckout } from '../../components/RightPanel/RightPanelCheckout';
import fordLogo from '../../assets/fordLogo/Ford_logo_flat.svg.png';
import { useStripeInvoice } from '../../components/Api/useStripe';
import { TermsAndConditionsStyledMobile } from '../../components/Styles/Style';

const Checkout = () => {
  const { jwtFromUrl } = useParams();
  const color = 'primary';
  const { invoice, loading, clientSecret, stripe, setPaid, paid, isErrorr } = useStripeInvoice({
    jwtFromUrl,
  });

  const [error, setIsError] = React.useState(false);

  const [dealerLogoUrl, setDealerLogoUrl] = React.useState<string>(fordLogo);

  const message = paid ? 'Payment Received' : 'Make a Payment';

  useEffect(() => {
    if (invoice && invoice.accountLogoDownloadUrl) {
      setDealerLogoUrl(invoice.accountLogoDownloadUrl);
    }
  }, [invoice]);
  return (
    <>
      <div className={styles.header}>
        <Header loading={loading} dealerLogoUrl={dealerLogoUrl} />
      </div>

      {isErrorr && !loading && (
        <Message error={isErrorr} message={'Invoice not found! Please check the payment link.'} />
      )}
      {!loading && invoice !== undefined && (
        <div className={c('fds-layout-grid', styles.layoutGrid)}>
          {invoice.invoiceStatus === 'paid' && (
            <Message error={isErrorr} message={'This invoice has already been paid.'} />
          )}

          <div className="fds-layout-grid__inner">
            <LeftPanel
              invoiceAmountDue={invoice.invoiceAmountDue}
              dueDate={invoice.dueDate}
              customerAddress={invoice.custommerAddressDto}
              customerName={invoice.customerName}
              invoiceNumber={invoice.invoiceNumber}
              message={message}
              invoicePdfLink={invoice.invoicePdfLink}
              items={invoice.items}
              color={color}
            />

            <RightPanelCheckout
              invoice={invoice}
              setPaid={setPaid}
              stripe={stripe}
              paid={paid}
              clientSecret={clientSecret}
              setIsError={setIsError}
              error={error}
              color={color}
            />
            <TermsAndConditionsStyledMobile>
              <TermsAndConditions />
            </TermsAndConditionsStyledMobile>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
