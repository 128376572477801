import InvoiceItems from '../InvoiceItems/InvoiceItems';
import styles from '../../pages/Checkout/Checkout.module.css';
import c from 'classnames';
import { FC, useState } from 'react';
import ApprovePayment from '../ApprovePayment/ApprovePayment';
import { Title } from '../Styles/Style';
import ApprovalModel from '../../models/QuoteModel';

interface QuoteDetailsProps {
  quote: ApprovalModel;
  color: string;
  accept: boolean;
  setAccept: (value: boolean) => void;
  jwtToken: string;
  setError: (value: boolean) => void;
  setErrorMessage: (value: string) => void;
}

export const RightPanelApproval: FC<QuoteDetailsProps> = props => {
  const { quote, color, accept, setAccept, jwtToken, setError, setErrorMessage } = props;
  const [typeName, setTypeName] = useState('');
  const [invalidNameMessage, setInvalidNameMessage] = useState(' ');

  return (
    <div
      className={c('fds-layout-grid__cell--span-6', styles.fdslayoutRight, styles.layoutContainer)}>
      <div className={styles.desktopItems}>
        <InvoiceItems
          dueDate={quote.quoteDto.expiresAt}
          customerAddress={quote.quoteDto.customerDto.customerAddress}
          customerName={quote.quoteDto.customerDto.customerName}
          orderNumber={quote.quoteDto.number}
          color={color}
        />
      </div>
      {accept && (
        <Title color={color}>
          <span>Thank you for approving our quote. Now we'll get you back on the road.</span>
        </Title>
      )}
      {!accept && (
        <Title color={color}>
          <span>Submit Approval</span>
          <hr className={c('fmc-divider--horizontal', styles.marginTop, styles.marginBotton)} />
          <br />
          <p>By submitting this form you agree to pay cost upon completion of the work</p>
          <br />
          <ApprovePayment
            jwtToken={jwtToken}
            setAccept={setAccept}
            setInvalidNameMessage={setInvalidNameMessage}
            setTypeName={setTypeName}
            typeName={typeName}
            invalidNameMessage={invalidNameMessage}
            orderAmountDue={quote.quoteDto.amountTotal}
            setError={setError}
            setErrorMessage={setErrorMessage}
          />
        </Title>
      )}
    </div>
  );
};
