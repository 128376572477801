import { useLocation, useParams } from 'react-router-dom';
import styles from '../Checkout/Checkout.module.css';
import Header from '../../components/Header/Header';
import React, { useEffect } from 'react';
import TermsAndConditions from '../../components/TermsAndConditions/TermsAndConditions';
import c from 'classnames';
import { Message } from '../../components/Message/Message';
import { LeftPanel } from '../../components/LeftPanel/LeftPanelInvoice';
import fordLogo from '../../assets/fordLogo/Ford_logo_flat.svg.png';
import { useFordPayInvoice } from '../../components/Api/useStripe';
import { TermsAndConditionsStyledMobile } from '../../components/Styles/Style';
import { RightPanelCheckout } from '../../components/RightPanel/RightPanelCheckoutFordPay';

const CheckoutFordPay = () => {
  const { jwtFromUrl } = useParams();
  const color = 'primary';
  const { invoice, loading, paid, isErrorr, setPaid } = useFordPayInvoice({
    jwtFromUrl,
  });

  sessionStorage.setItem('token', jwtFromUrl ? jwtFromUrl : '');
  const paidFordPay = useLocation().search.replace('?&paid=', '');

  useEffect(() => {
    if (invoice && invoice.accountLogoDownloadUrl) {
      setDealerLogoUrl(invoice.accountLogoDownloadUrl);
    }
    if (paidFordPay === 'succeeded') {
      setPaid(true);
    }
  }, [invoice]);

  const [dealerLogoUrl, setDealerLogoUrl] = React.useState<string>(fordLogo);
  const [error, setIsError] = React.useState(false);
  const message = paid ? 'Payment Received' : 'Make a Payment';

  return (
    <>
      <div className={styles.header}>
        <Header loading={loading} dealerLogoUrl={dealerLogoUrl} />
      </div>

      {!loading && invoice !== undefined && (
        <div className={c('fds-layout-grid', styles.layoutGrid)}>
          {invoice.invoiceStatus === 'paid' ||
            (paidFordPay && paidFordPay === 'succeeded' && (
              <Message error={isErrorr} message={'This invoice has already been paid.'} />
            ))}

          {isErrorr && !loading && (
            <Message
              error={isErrorr}
              message={'Invoice not found! Please check the payment link.'}
            />
          )}

          {paidFordPay && paidFordPay !== 'succeeded' && (
            <Message error={true} message={'Invoice not paid!'} />
          )}

          <div className="fds-layout-grid__inner">
            <LeftPanel
              invoiceNumber={invoice.invoiceNumber}
              invoiceAmountDue={invoice.invoiceAmountDue}
              customerAddress={invoice.custommerAddressDto}
              customerName={invoice.customerName}
              color={color}
              message={message}
              invoicePdfLink={invoice.invoicePdfLink}
              dueDate={invoice.dueDate}
              items={invoice.items}
            />
            <RightPanelCheckout
              invoice={invoice}
              setPaid={setPaid}
              paid={paid}
              setIsError={setIsError}
              error={error}
              color={color}
            />

            <TermsAndConditionsStyledMobile>
              <TermsAndConditions />
            </TermsAndConditionsStyledMobile>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutFordPay;
