import { useEffect, useState } from 'react';
import InvoiceModel from '../../models/InvoiceModel';
import { Stripe } from '@stripe/stripe-js';
import { CardNumberElementComponent } from '@stripe/react-stripe-js';

interface UsePaymentCardProps {
  nameOnCard: string;
  stripe: Stripe | null;
  invoice: InvoiceModel;
  CardNumberElement: CardNumberElementComponent;
  elements: any;
  setPaid: (value: boolean) => void;
  setIsError: (value: boolean) => void;
}

function useCreatePaymentMethod(props: UsePaymentCardProps) {
  const [result, setPaymentMethod] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [processingError, setProcessingError] = useState(null);
  const { nameOnCard, stripe, invoice, elements, CardNumberElement, setPaid, setIsError } = props;

  async function submit() {
    setProcessingError(null);
    setProcessing(true);
  }

  async function confirmCardSetup(paymentMethod: any) {
    if (!stripe) {
      return;
    }
    try {
      await stripe
        .confirmCardPayment(
          invoice.paymentIntentSecretId,
          { payment_method: paymentMethod.paymentMethod.id },
          { handleActions: false },
        )
        .then((data: any) => {
          if (data.paymentIntent.status === 'succeeded') {
            setPaid(true);
            setProcessing(true);
            return;
          }
          setPaymentMethod(paymentMethod);
        });
    } catch (error) {
      setIsError(true);
      setProcessing(false);
    }
  }

  async function createPaymentMethod() {
    const cardNumberElement = elements.getElement(CardNumberElement);
    if (stripe && elements) {
      await stripe
        .createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name: nameOnCard,
          },
        })
        .then(confirmCardSetup);
    }
  }

  useEffect(() => {
    setProcessing(false);
  }, [processingError]);

  useEffect(() => {
    if (processing) {
      if (!stripe || !elements) {
        return;
      }

      createPaymentMethod();
    }
  }, [processing]);

  return {
    _confirmCardSetup: confirmCardSetup,
    error: processingError,
    paymentMethod: result,
    processing,
    submit,
  };
}

export default useCreatePaymentMethod;
