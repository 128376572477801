import styles from './InvoiceItems.module.css';
import moment from 'moment';
import { FC } from 'react';
import { CustomerAddress } from '../../models/InvoiceModel';
import c from 'classnames';
import { ColorsRules } from '../Styles/Style';

interface InvoicePayamentProps {
  dueDate: string;
  orderNumber: string;
  customerName: string;
  customerAddress: CustomerAddress | null;
  color: string;
}

const InvoiceItems: FC<InvoicePayamentProps> = props => {
  const { dueDate, customerName, orderNumber, customerAddress, color } = props;

  return (
    <div className="invoiceItems">
      <table className="fmc-table fmc-table--no-borders">
        <tbody>
          <tr>
            <td className={c(styles.cellLabel)}>Service Date</td>
            <td>
              <ColorsRules color={color}>{moment(dueDate).format('MMMM DD, YYYY')}</ColorsRules>
            </td>
          </tr>
          <tr>
            <td className={styles.cellLabel}>Your Name</td>
            <td>
              <ColorsRules color={color}>{customerName}</ColorsRules>
            </td>
          </tr>
          <tr>
            <td className={styles.cellLabel}>Service Address</td>
            {customerAddress && (
              <td>
                <ColorsRules color={color} data-testid="addressLine1">
                  {customerAddress.line1}
                </ColorsRules>
              </td>
            )}
          </tr>
          {customerAddress && (
            <tr data-testid="addressLine2">
              <td></td>
              <td>
                <ColorsRules
                  color={
                    color
                  }>{`${customerAddress.state} ${customerAddress.postalCode}`}</ColorsRules>
              </td>
            </tr>
          )}
          <tr>
            <td className={styles.cellLabel}>Repair Order #</td>
            <td>
              <ColorsRules color={color}>{orderNumber}</ColorsRules>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default InvoiceItems;
