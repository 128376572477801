import React, { useEffect } from 'react';
import qs from 'query-string';
import { Stripe } from '@stripe/stripe-js';

const CheckoutFinalizedFordPay = () => {
  const [stripe] = React.useState<Stripe | null>(null);

  const query = qs.parse(location.search);
  const {
    account_id,
    payment_type,
    payment_intent,
    redirect_status,
    payment_intent_client_secret,
  } = query ?? {};

  const jwtURL = sessionStorage.getItem('token');
  async function confirmCardSetup() {
    window.location.href = '/checkoutfordpay/' + jwtURL + '?&paid=' + redirect_status;
  }

  useEffect(() => {
    confirmCardSetup();
  }, [stripe, account_id, payment_type, payment_intent, payment_intent_client_secret]);

  return <div>{}</div>;
};

export default CheckoutFinalizedFordPay;
