import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC, FormEvent, useState } from 'react';
import InvoiceModel from '../../models/InvoiceModel';
import { FormPaymentCard } from './FormPaymentCard';
import useCreatePaymentMethod from './useCreatePaymentMethod';

interface PaymentCardProps {
  invoice: InvoiceModel;
  invoiceAmountDue: number;
  setPaid: (value: boolean) => void;
  setIsError: (value: boolean) => void;
}

const PayWithCard: FC<PaymentCardProps> = props => {
  const { invoice, setPaid, invoiceAmountDue, setIsError } = props;
  const [invalidNameMessage, setInvalidNameMessage] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const stripe = useStripe();
  const elements = useElements();

  const propsPayment = {
    invoice,
    nameOnCard,
    stripe,
    elements,
    CardNumberElement,
    setPaid,
    setIsError,
  };

  const { processing, submit } = useCreatePaymentMethod(propsPayment);

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!nameOnCard) setInvalidNameMessage('Card name cannot be blank');
    if (!stripe || !elements) {
      return;
    }
    await submit();
    processing ? setPaid(true) : setPaid(false);
  }

  return (
    <FormPaymentCard
      onSubmit={onSubmit}
      invoiceAmountDue={invoiceAmountDue}
      invalidNameMessage={invalidNameMessage}
      setInvalidNameMessage={setInvalidNameMessage}
      nameOnCard={nameOnCard}
      setNameOnCard={setNameOnCard}
    />
  );
};

export default PayWithCard;
