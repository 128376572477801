import { FC } from 'react';
import OrderDetails from '../Details/Details';
import { CustomerAddress } from '../../models/InvoiceModel';
import { Item } from '../../models/QuoteModel';
import styles from '../../pages/Checkout/Checkout.module.css';
import c from 'classnames';
import { TermsAndConditionsStyled } from '../Styles/Style';
import TermsAndConditions from '../TermsAndConditions/TermsAndConditions';

interface QuoteDetailsProps {
  quotePdfLink: string;
  message: string;
  quoteAmountDue: number;
  dueDate: string;
  customerAddress: CustomerAddress;
  customerName: string;
  quoteNumber: string;
  items: Item[];
  color: string;
  accept: boolean;
}

export const LeftPanelQuote: FC<QuoteDetailsProps> = props => {
  const {
    quoteNumber,
    quotePdfLink,
    customerAddress,
    quoteAmountDue,
    customerName,
    items,
    dueDate,
    message,
    color,
    accept,
  } = props;

  return (
    <div
      className={c('fds-layout-grid__cell--span-6', styles.fdslayoutLeft, styles.layoutContainer)}>
      <OrderDetails
        dueDate={dueDate}
        customerAddress={customerAddress}
        customerName={customerName}
        orderNumber={quoteNumber}
        orderAmountDue={quoteAmountDue}
        message={message}
        orderPdfLink={quotePdfLink}
        items={items}
        color={color}
        accept={accept}
      />
      <TermsAndConditionsStyled>
        <TermsAndConditions />
      </TermsAndConditionsStyled>
    </div>
  );
};
