import { loadStripe, Stripe } from '@stripe/stripe-js';

export function initializeStripe(accountId?: string): Promise<Stripe | null> {
  const publishableKey = process.env.REACT_APP_STRIPE_PK ?? '';
  const platformAccount = process.env.REACT_APP_PLATFORM_ACCOUNT;
  const errorAccountID = accountId === '';
  if (errorAccountID) {
    return Promise.resolve(null);
  }
  const mainAccount = accountId === platformAccount;
  if (mainAccount) {
    return loadStripe(publishableKey, { apiVersion: '2022-08-01' });
  }
  return loadStripe(publishableKey, { apiVersion: '2022-08-01', stripeAccount: accountId });
}
